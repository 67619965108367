<template>
  <v-overlay :value="inProgress" :opacity="opacity" color="white">
    <v-row class="d-flex justify-center align-center secondary--text mb-12">
      <img :src="require('@/assets/loader.gif')" height="300" class="mb-3" />
      <v-col class="col-12">
        <h2 class="text-center">{{overlaymessage}}</h2>
        <p class="caption text-center" v-if="overlaymessage2">{{overlaymessage2}}</p>
      </v-col>
    </v-row>
  </v-overlay>
</template>

<script>
export default {
  name: "OverlayLoading",

  data() {
    return {
      opacity: 0.95
    };
  },

  props: {
    overlaymessage: {
      default: () => ({})
    },
    overlaymessage2: {
      default: () => ({})
    },
    inProgress: {
      default: () => ({})
    }
  }
};
</script>

<style>
</style>